.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 78px;
  left: 0px;
  top: 0px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.06);
  z-index: 5; }
  .header .logo {
    min-width: 200px;
    width: 5%; }
    .header .logo img {
      width: 175px; }
    @media (max-width: 450px) {
      .header .logo {
        min-width: 180px; }
        .header .logo img {
          width: 150px; } }
  .header .menu-options-container {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .header .menu-options-container .desktop-menu-options-container {
      display: flex;
      font-size: 16px;
      line-height: 18px; }
      .header .menu-options-container .desktop-menu-options-container .express-menu-options {
        display: flex;
        justify-content: flex-start;
        align-items: center; }
      .header .menu-options-container .desktop-menu-options-container .full-menu-options {
        display: flex;
        justify-content: space-between;
        align-items: center; }
    .header .menu-options-container .dropdown-options {
      display: flex;
      justify-content: flex-start;
      align-items: center; }
    .header .menu-options-container .option-link {
      color: #4F4F4F;
      opacity: 0.8;
      border-bottom: 4px solid transparent;
      padding: 28px 0px;
      margin: 0 10px; }
      .header .menu-options-container .option-link:hover {
        color: #004ED0;
        border-bottom: 4px solid #004ED0;
        opacity: 100%;
        transition: all 0.8s ease-out; }
      .header .menu-options-container .option-link:focus {
        color: #004ED0;
        border-bottom: 4px solid #004ED0;
        opacity: 100%; }
    .header .menu-options-container .tab-and-mobile-menu-container {
      position: relative;
      display: flex;
      justify-content: flex-start; }
      .header .menu-options-container .tab-and-mobile-menu-container:hover {
        cursor: pointer; }
  .header .sign-in-and-signout-container {
    width: 15%;
    min-width: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative; }
    .header .sign-in-and-signout-container .vertical-bar {
      margin: 0 1em; }
    .header .sign-in-and-signout-container .login, .header .sign-in-and-signout-container .icon {
      font-size: 16px;
      text-transform: capitalize;
      opacity: 0.7; }
      .header .sign-in-and-signout-container .login:hover, .header .sign-in-and-signout-container .icon:hover {
        cursor: pointer;
        opacity: 1; }
    .header .sign-in-and-signout-container .name {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.934);
      text-transform: capitalize; }
    .header .sign-in-and-signout-container .icon {
      margin-left: 10px; }
    .header .sign-in-and-signout-container .down-arrow, .header .sign-in-and-signout-container .person-icon {
      font-size: 1.5em; }
    .header .sign-in-and-signout-container .down-arrow {
      margin-right: 5px; }
    .header .sign-in-and-signout-container .search {
      padding-left: 20px;
      position: relative;
      right: 0px; }
  .header .notification-icon-container {
    width: 15%;
    min-width: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative; }
    .header .notification-icon-container .bell-icon {
      opacity: 0.7;
      cursor: pointer; }
      .header .notification-icon-container .bell-icon:hover {
        opacity: 1; }
  @media all and (max-width: 1300px) {
    .header .menu-options-container {
      width: 100%; }
      .header .menu-options-container .desktop-menu-options-container {
        font-size: 14px; } }
  @media all and (max-width: 1300px) and (max-width: 500px) {
    .header .menu-options-container .tab-and-mobile-menu-container {
      position: unset; } }

@media all and (max-width: 1300px) and (max-width: 450px) {
  .header .menu-options-container {
    display: flex;
    justify-content: flex-start; }
  .header .sign-in-and-signout-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: unset;
    min-width: 150px; }
    .header .sign-in-and-signout-container .login, .header .sign-in-and-signout-container .icon {
      font-size: 14px; }
    .header .sign-in-and-signout-container .name {
      display: none; } }
